<template>
    <slot :open="open" v-if="!noDefaultSlot">
        <button class="btn btn-link btn-sm" @click="open"
            :title="$t('View current record info')"
            :disabled="currentRow?.ID == null && currentRow?.PrimKey == null">
            <i class="bi bi-info-circle"></i>
        </button>
    </slot>
    <ODialog v-model:show="showDialog" ref="modalRef" :title="$t('Record info for selected row')" size="md"
        @show="getRecordInfo" @hidden="onHidden" @shown="onShown">
        <div class="o365-dialog-body">
            <template v-if="procedureError">
                <div class="d-flex mx-2">
                    <i class="bi bi-exclamation-triangle-fill text-danger me-2"></i>
                    <span>{{procedureError}}</span>
                </div>
            </template>
            <template v-else>
                <div class="record-info-item placeholder-glow">
                    <h6>{{ $t('ID/PrimKey') }}</h6>
                    <span v-if="isLoading" class="placeholder w-75"></span>
                    <span v-else>{{ recordInfo.ID ?? '-' }} / {{ recordInfo.PrimKey ?? '-' }}</span>
                </div>
                <div class="record-info-item placeholder-glow">
                    <h6>{{ $t('Created') }}</h6>
                    <span v-if="isLoading" class="placeholder w-50"></span>
                    <span v-else v-person-hover="recordInfo.CreatedBy_ID">{{ recordInfo.Created ? $formatDate(recordInfo.Created, 'General Date Short Time') : '-' }} / {{ recordInfo.CreatedBy ?? '-' }} ({{recordInfo.CreatedBy_ID ?? '-'}})</span>
                </div>
                <div class="record-info-item placeholder-glow">
                    <h6>{{ $t('Updated') }}</h6>
                    <span v-if="isLoading" class="placeholder w-50"></span>
                    <span v-else v-person-hover="recordInfo.UpdatedBy_ID">{{ recordInfo.Updated ? $formatDate(recordInfo.Updated, 'General Date Short Time') : '-' }} / {{ recordInfo.UpdatedBy ?? '-' }} ({{recordInfo.UpdatedBy_ID ?? '-'}})</span>
                </div>
            </template>
        </div>
    </ODialog>
</template>

<script setup lang="ts">
import { ODialog } from 'o365-ui-components';
import { getDataObjectById } from 'o365-dataobject';
import { getOrCreateProcedure } from 'o365-modules';
import vPersonHover from 'system.libraries.vue.directive.personHover.ts';
import { ref, computed, watch } from 'vue';

export interface IRecordInfoProps {
    dataObject?: any,
    row?: any,
    noDefaultSlot?: boolean
};

const props = defineProps<IRecordInfoProps>();

const emit = defineEmits<{
    (e: 'hidden'): void
}>();

const modalRef = ref<any>(null);
const showDialog = ref(false);
const recordInfo = ref({});
const isLoading = ref(true);
const procedureError = ref(null);
const procRecordInfo = getOrCreateProcedure({
    id: 'o_procRecordInfo',
    procedureName: 'sstp_O365_GetRecordInfo',
});

const currentDataObject = computed(() => {
    return props.dataObject ?? getDataObjectById(props.row?.dataObjectId, props.row?.appId)
});
const currentRow = computed(() => {
    return props.row ?? props.dataObject.current;
});


async function getRecordInfo() {
    recordInfo.value = {};
    isLoading.value = true;
    procedureError.value = null;
    try {
        const result = await procRecordInfo.execute({
            PrimKey: currentRow.value.PrimKey,
            ID: String(currentRow.value.ID),
            View: currentDataObject.value.viewName,
        });
        recordInfo.value = result.Table[0];
    } catch (ex) {
        procedureError.value = ex;
    }
    isLoading.value = false;
}

let ctWatcher: (()=>void)|undefined = undefined;
function onShown() {
    if (ctWatcher) { ctWatcher(); }
    ctWatcher = watch(() => currentRow.value?.index, () => {
        getRecordInfo();
    });
}

function onHidden() {
    if (ctWatcher) {
        ctWatcher();
        ctWatcher = undefined;
    }
    emit('hidden');
}

function open() {
    showDialog.value = true;
    // modalRef.value.show();
}

defineExpose({ modalRef, open });

</script>

<style scoped>
.record-info-item {
    margin-bottom: 1rem;
}
</style>